import { memo, useContext, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { PlayerContext } from './PlayerProvider';

const PlayerStyled = styled.div`
  position: fixed;
  height: 80px;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px;

  audio {
    width: 100%;
  }
`;

export const Player = memo(() => {
  const { song } = useContext(PlayerContext);
  const ref = useRef();

  useEffect(() => {
    if (song) {
      ref.current.play();
    }
  }, [song]);

  return (
    <PlayerStyled>
      <audio ref={ref} controls src={song} />
    </PlayerStyled>
  );
});
