import styled from 'styled-components';
import { ALBUMS } from '../albums';
import { Album } from './Album';

const Wrapper = styled.div`
  padding: 40px;
  display: flex;
  flex-direction: column;
  row-gap: 40px;
  background-color: #fff;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding-bottom: 100px;

  @media screen and (max-width: 600px) {
    align-items: center;
  }
`;

const Copyright = styled.div`
  text-align: center;
`;

export const Discography = () => (
  <Wrapper>
    {[...ALBUMS].reverse().map((album) => (
      <Album key={album.name} album={album} />
    ))}
    <Copyright>&copy; All Rights Reserved</Copyright>
  </Wrapper>
);
