import { useContext } from 'react';
import styled from 'styled-components';
import { tokenize } from '../albums';
import { PlayerContext } from './PlayerProvider';

const TrackNumber = styled.div`
  width: 20px;
  text-align: right;
  color: #999;
  margin-right: 5px;
  font-size: 0.9rem;
  flex-shrink: 0;
`;

const StyledButton = styled.button`
  border: none;
  background-color: transparent;
  cursor: pointer;
  font-size: 0.9rem;
  color: ${({ $playing }) => ($playing ? 'blue' : '#000')};
  flex: 1;
  text-align: left;
`;

const StyledLi = styled.li`
  display: flex;
`;

export const Track = ({ album, track, number }) => {
  const file = `https://audio.alexmorrise.com/${tokenize(
    album.name
  )}/${tokenize(track)}.mp3`;

  const { song, setSong } = useContext(PlayerContext);

  return (
    <StyledLi>
      <TrackNumber>{number}</TrackNumber>
      <StyledButton
        $playing={file === song}
        type="button"
        onClick={() => setSong(file)}
      >
        {track}
      </StyledButton>
    </StyledLi>
  );
};
