import { Discography } from './components/Discography';
import { Player } from './components/Player';
import { PlayerProvider } from './components/PlayerProvider';

export const App = () => (
  <PlayerProvider>
    <Discography />
    <Player />
  </PlayerProvider>
);
