import styled from 'styled-components';
import { tokenize } from '../albums';
import { Track } from './Track';

const Wrapper = styled.div`
  flex-shrink: 0;
  width: 200px;
  height: 200px;
  position: relative;

  img {
    width: 200px;
    height: auto;
  }

  &:before {
    content: '';
    display: block;
    width: 200px;
    height: 200px;
    position: absolute;
    box-shadow: 0 0 0 5px #000 inset;
  }

  @media screen and (max-width: 450px) {
    margin-bottom: 20px;
    width: 100%;
    height: auto;
    aspect-ratio: 1;

    img {
      width: 100%;
      height: auto;
      aspect-ratio: 1;
    }

    &:before {
      width: 100%;
      height: auto;
      aspect-ratio: 1;
    }
  }
`;

const AlbumWrapper = styled.div`
  display: flex;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  column-gap: 15px;

  @media screen and (max-width: 450px) {
    flex-direction: column;
  }
`;

const StuffWrapper = styled.div`
  flex: 1;
`;

const AlbumName = styled.div`
  font-size: 18px;
  font-weight: 600;

  @media screen and (max-width: 450px) and (min-width: 351px) {
    font-size: 24px;
  }

  @media screen and (max-width: 350px) and (min-width: 250px) {
    font-size: 20px;
  }
`;

const TrackList = styled.ol`
  padding: 0;
  list-style: none;
  margin: 0;
`;

const Released = styled.div`
  font-size: 14px;
  color: #999;
  text-align: right;

  @media screen and (max-width: 600px) {
    text-align: left;
  }
`;

const NameAndReleased = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  column-gap: 10px;

  @media screen and (max-width: 600px) {
    text-align: left;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
`;

export const Album = ({ album }) => (
  <AlbumWrapper>
    <Wrapper>
      <img
        src={`https://art.alexmorrise.com/${tokenize(album.name)}.jpg`}
        alt={album.name}
      />
    </Wrapper>
    <StuffWrapper>
      <NameAndReleased>
        <AlbumName>{album.name}</AlbumName>
        <Released>{album.released}</Released>
      </NameAndReleased>
      <TrackList>
        {album.tracks.map((track, index) => (
          <Track key={track} number={index + 1} track={track} album={album} />
        ))}
      </TrackList>
    </StuffWrapper>
  </AlbumWrapper>
);
